import { Component, OnInit } from '@angular/core';
import { AppService } from '../app.service';
import { NgForm } from '@angular/forms';
import * as CryptoJS from 'crypto-js';
import { environment } from '../../environments/environment';
import {ActivatedRoute} from '@angular/router';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
declare var swal:any;
@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  public form: NgForm;
  model: any = {};
  result={};
  message:any;
  constructor(private appService: AppService,
    public route: ActivatedRoute,
    public router:Router) { }

  ngOnInit() {
    console.log(this.route.snapshot.queryParams.id)
    this.model.token = this.route.snapshot.queryParams.token;
    this.model.id = this.route.snapshot.queryParams.id
    // this.route.params.subscribe(map => map);
    // this.model.reset_password_token = this.route.snapshot.queryParams.reset_password_token;
    // this.model.type = this.route.snapshot.queryParams.type;
  }

  submit() {

    this.appService.ResetPassword(this.model).subscribe(res => {

      console.log("message", res.message)

      if (res.status == 400) {
        this.result = res;
        this.message = res.message;
      }
      else if (res.status == 200) {
        this.result = res;
        this.message = res.message;
        Swal.fire('Success!', res.message);
        this.router.navigate(['login'])
      } else {
        this.result = res;
        this.message = res.message;

      }


    })
  }

}
