import { Component, OnInit, ViewChild } from '@angular/core';
import { Router,RouterModule } from '@angular/router';
 import {AppService} from "../app.service";
 import * as CryptoJS from 'crypto-js';
 import {environment} from '../../environments/environment';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  
  @ViewChild(NgForm, {static: false}) ngForm: NgForm;

 // public form : NgForm;
  form_heading:string = 'Login';
  err_message:string;

  model:any ={
    user_name:"",
    password:"",
    lang:""
  };

  lang:string;

  constructor( private router:Router,
    private service:AppService) {

  }

  ngOnInit() {
    this.lang = localStorage.getItem('language');
    if(this.lang){
      if(this.lang == 'DE'){
        this.model.lang = "DE";
      }
      else if(this.lang == 'FR'){
        this.model.lang = "FR";
      }
      else if(this.lang == 'IT'){
        this.model.lang = "IT";
      }else{
        this.model.lang = "EN";
      }

    }
  }
  login(){

    this.service.Login(this.model).subscribe(res=>{

      if(res.status == 400){
        this.err_message = res.message;
      }
      if(res.status == 200){

        const user_data = res.result;
        localStorage.setItem('isLoggedIn','yes');
        localStorage.setItem('userType', user_data.type);

        const user_info = CryptoJS.AES.encrypt(JSON.stringify(user_data),environment.secret_key,).toString();
        
        localStorage.setItem('user_data', user_info);
       this.router.navigate(['/sites/site-list']);
      }else{
        console.log(res)
        this.err_message = res.message;
       // this.toastr.error(res.message, 'Error!');
      }
    },
    err=>{
      if(err.status == 409){
       // this.toastr.error('This category already exist');
       alert('Error');
      }
   });


  }
}
