import { Component, OnInit } from '@angular/core';
import {AppService} from "../app.service";
import {Router} from '@angular/router';
import * as CryptoJS from 'crypto-js';
import {environment} from '../../environments/environment';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnInit {

  model:any = {fname:'', lname:'', email:'', phone:'',password:''};
  message:"";
  status:200;

  language:string;
  constructor(private appService:AppService,
              private router: Router) { }

  ngOnInit() {
    this.language = localStorage.getItem('language')?localStorage.getItem('language'):'EN';
  }
  SignUp(){
    console.log(this.language)
    var data = {};
    var message = "";

       data = {
        EN_first_name:this.model.fname,
        EN_last_name:this.model.lname,
        IT_first_name:this.model.fname,
        IT_last_name:this.model.lname,
        FR_first_name:this.model.fname,
        FR_last_name:this.model.lname,
        IN_first_name:this.model.fname,
        IN_last_name:this.model.lname,
        password:this.model.password,
        email:this.model.email,
        phone:this.model.phone,
        lang: this.language
       }
   
   // console.log(data)
    this.appService.SignUp(data).subscribe(res=>{
      console.log(res);
      if(res.status == 200){
        localStorage.setItem('isLoggedIn','yes');
        console.log(res)
        const user_data = res.result;
        const user_info = CryptoJS.AES.encrypt(JSON.stringify(user_data),environment.secret_key, ).toString();
        
        localStorage.setItem('user_data', user_info);
       this.router.navigate(['/sites/my-profile']);
      }else{
       // this.toastr.error(res.message, 'Error!');
       console.log("message === ", res.message);
       this.message = res.message;
       this.status = res.status;
      }
      // if(res.status == 200){
      //   localStorage.setItem('isLoggedIn','yes');
      //   this.router.navigate(['/sites/site-list'])
      // }
    })
  }

}
