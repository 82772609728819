import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../environments/environment';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class AppService {

  constructor(private httpClient:HttpClient) { }

  Login(data):Observable<any>{
     return this.httpClient.post(environment.api_url+"login", data);
  }
  SignUp(data):Observable<any>{
    return this.httpClient.post(environment.api_url+"register", data);
  }

  ForgotPassword(data):Observable<any>{
    return this.httpClient.post(environment.api_url+"forgot-password", data);
 }

 ResetPassword(data):Observable<any>{
  return this.httpClient.post(environment.api_url+"reset-password", data);
 }

}
