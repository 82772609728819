// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  api_url:"https://api.mr-hector.ch/web/",
  secret_key:"123xyz456",
  image_url: 'http://192.168.1.87/hector/public/uploads/user/',
  site_img: 'http://192.168.1.87/hector/public/uploads/site/',
  testimonial_image: 'http://192.168.1.87/hector/public/uploads/testimonial/'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
