import { Component, OnInit } from '@angular/core';
import {AppService} from '../app.service';
import { NgForm } from '@angular/forms';
import * as CryptoJS from 'crypto-js';
import {environment} from '../../environments/environment';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  public form : NgForm;
  language:string;

  // public form : NgForm
   message:any;
   form_heading:string = 'Login';
   err_message:string = "";
   result = {};

   model:any ={
     email:"",
   };

  constructor(private appService: AppService) { }

  ngOnInit() {

  }
  submit(){

    this.language = localStorage.getItem('language')?localStorage.getItem('language'):'EN';

    this.appService.ForgotPassword({email:this.model.email, lang:this.language}).subscribe(res=>{

     console.log("message", res.message)
      
      if(res.status == 400){
        this.result = res;
        this.message=res.message;
      }
     else if(res.status == 200){
      this.result = res;
      this.message=res.message;
      }else{
        this.result = res;
        this.message=res.message;

      }


    })
  }

}
