import { Component, OnInit } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

   title = 'web';
   language:string;
   isLoggedIn:string;

   constructor(public translate: TranslateService) {

    translate.addLangs(['en', 'fr', 'it', 'de']);
    translate.setDefaultLang('en');

    var lang = localStorage.getItem('language')?  localStorage.getItem('language').toString().toLowerCase(): 'en';
     translate.use(lang);
     console.log("translate.use : ",lang);
    //const browserLang = translate.getBrowserLang();
   // translate.use(browserLang.match(/en|fr/) ? browserLang : 'en');
   // console.log(browserLang);

   this.language = localStorage.getItem('language')?localStorage.getItem('language'):'EN';
   this.isLoggedIn = localStorage.getItem('isLoggedIn')?localStorage.getItem('isLoggedIn'):'no';

    localStorage.setItem('language', this.language);
    console.log('Defult set language: ',this.language)


   }

   ngOnInit() {
   // this.translate.use('en');
   // console.log( this.translate.currentLang);

    
   }
}
